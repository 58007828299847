<template>
  <vx-card class="payment-gateway-menu-page"
           :class="{'main-box top-zero-radius': !paymentGatewayId, 'no-box-shadow': paymentGatewayId > 0} "
           v-if="Object.keys(paymentGateway).length > 0">

    <profile-main-box :profile="getPayGatewayProfile"
                      :default-action="profileStatus"
                      :actions-list="actionsList"
                      @payment-gateway-turnover="profileStatus = 'payment-gateway-turnover'"
                      @payment-gateway-receive="profileStatus = 'payment-gateway-receive'"
                      @payment-gateway-activity-log="profileStatus = 'payment-gateway-activity-log'">

      <payment-gateway-transactions v-if="profileStatus === 'payment-gateway-turnover'" :payment-gateway-id="paymentGatewayId || parseInt($route.params.id)"/>

      <received-payment-gateway-balance v-else-if="profileStatus === 'payment-gateway-receive'" :payment-gateway-id="paymentGatewayId"/>

      <payment-gateway-activities-log v-else-if="profileStatus === 'payment-gateway-activity-log'" />

      <edit-payment-gateway v-else-if="profileStatus === 'editPaymentGateway'" :payment-gateway-id="paymentGatewayId || parseInt($route.params.id)" />
    </profile-main-box>

    <button id="editPaymentGateway" v-show="false" @click="profileStatus = 'editPaymentGateway'"></button>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {mapGetters} from "vuex";
import {addComma, customParseFloat} from '../../../../../../assets/js/functions'
import {getPaymentGateway} from '../../../../../../http/requests/treasury/paymentGateways'
import ProfileMainBox from '../../../../../../components/profileMainBox/profileMainBox2'
import CustomIcon from '../../../../../../components/customIcon/customIcon'
import PaymentGatewayActivitiesLog from "../activitiesLog/paymentGatewayActivitiesLog.vue";

export default {
  name: 'paymentGateway',
  components: {
    PaymentGatewayActivitiesLog,
    EditPaymentGateway: () => import("../edit/editPaymentGateway.vue"),
    ReceivedPaymentGatewayBalance: () => import('../receive/receivedPaymentGatewayBalance'),
    PaymentGatewayTransactions: () => import('../transactions/paymentGatewayTransactions'),
    CustomIcon,
    ProfileMainBox
  },
  metaInfo () {
    return {
      title: this.bank ? this.$t('treasury.paymentGateways.paymentGateway.dynamicTitle', {paymentGateway: this.paymentGateway.title}) : this.$t('treasury.paymentGateways.paymentGateway.title')
    }
  },
  props: {
    paymentGatewayId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      profileStatus: 'payment-gateway-turnover',
      actions: {
        toolbar: [
          {
            id: 'editPaymentGateway',
            icon: 'EDIT',
            iconPack: 'useral',
            color: 'warning',
            permission: 'payment_gateway.update'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'paymentGateways'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      },
      paymentGateway: {},
      actionsList: [
        {
          event: 'payment-gateway-turnover',
          name: 'transactionsPaymentGateway',
          i18n: 'treasury.paymentGateways.paymentGateway.actions.transactions',
          icon: 'icon-layers',
          iconPack: 'feather'
        },
        {
          event: 'payment-gateway-receive',
          name: 'receivePaymentGateway',
          i18n: 'treasury.paymentGateways.paymentGateway.actions.receive',
          icon: 'DOLLAR_SIGN',
          iconPack: 'useral'
        },
        {
          event: 'payment-gateway-activity-log',
          name: 'activitiesLogPaymentGateway',
          params: {id: this.paymentGatewayId || this.$route.params.id},
          i18n: 'treasury.paymentGateways.paymentGateway.actions.logs',
          icon: 'LAST_ACTIVITIES',
          iconPack: 'useral',
          permission: 'activity_logs.show'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    getPayGatewayProfile () {
      const paymentGateway = {
        avatar: this.paymentGateway.image ? this.paymentGateway.image : '',
        name: !this.paymentGateway ? this.paymentGateway.title : '',
        importantData: [],
        description: this.paymentGateway.description && this.paymentGateway.description.length > 1 ? this.paymentGateway.description : null
      }

      paymentGateway.importantData.push({
        key: this.$t('treasury.paymentGateways.paymentGateway.importantData.type'),
        value: this.paymentGateway.type && this.paymentGateway.type === 1 ? this.$t('treasury.paymentGateways.types.online') : this.$t('treasury.paymentGateways.types.pos')
      })

      paymentGateway.importantData.push({
        key: this.$t('treasury.paymentGateways.paymentGateway.importantData.bank'),
        value: this.paymentGateway.bank ? this.paymentGateway.bank.name : '-',
        valueClass: 'text-primary'
      })

      return paymentGateway
    }
  },
  created () {
    if (this.$route.name === 'paymentGateway') {
      if (this.isMobile) {
        this.actions.toolbar[0].id = {name: 'editPaymentGateway', params: {id: this.$route.params.id}}
        this.actions.toolbar[0].type = 'link'
      }
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      }, 50)
    }

    this.getPaymentGateway()
  },
  methods: {
    getPaymentGateway () {
      getPaymentGateway(this.paymentGatewayId || this.$route.params.id).then(response => {

        this.paymentGateway = response.data.data
        this.$store.dispatch('setPageTitle', this.$t('treasury.paymentGateways.paymentGateway.title'))

        const balance_index = this.actionsList.map(e => e.event).indexOf('payment-gateway-turnover')
        if (balance_index > -1) {
          this.actionsList[balance_index].extra = {
            value: customParseFloat(this.paymentGateway.balance || 0),
            type: 'price'
          }
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.paymentGatewaysChanged': {
      handler (val) {
        if (val) {
          this.paymentGateway = {}
          this.getPaymentGateway()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.payment-gateway-menu-page {

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
</style>
